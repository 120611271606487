.accordion_heading_table td{
    min-width: 140px;
    width: 100%;
   
  }
  .create_custome_acc_btn{
    padding: 10px 10px;
    border: 1px solid;

  }

.costmize_body_content th,td {    
word-wrap: break-word;    
}
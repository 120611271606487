@import url("https://fonts.googleapis.com/css2?family=Poppins:weight@100;200;300;400;500;600;700;800&display=swap");

.product_size {
    height: 100px;
    width: 100px;
  }

  .product--section {
    width: 700px;
    background-color: #fff;
    padding: 2rem;
  }

  .product--cart .product--cart--inner {
    display: flex;
    gap: 10px;
  }

  .product--cart {
    position: relative;
    padding: 20px 0 30px;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
  }

  .remove--product {
    position: absolute;
    bottom: 30px;
    left: 45px;
    font-size: 14px;
  }

  .remove--product button {
    border: none;
    background-color: transparent;
    color: rgb(164, 29, 35);
    cursor: pointer;
  }

  .product--cart input:focus,
  .product--cart select:focus {
    outline: black dotted 1px;
  }

  .product--increment img {
    width: 20px;
  }

  .product--size-data h3 {
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
  }

  .product--size-data h5 {
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
  }

  .product--quantity .product-q-inner {
    position: relative;
    background-color: white;
    border: 1px solid rgb(198, 200, 202);
    border-radius: 2px;
  }

  .product--quantity .product-q-inner label {
    position: absolute;
    pointer-events: none;
    left: 16px;
    bottom: 70%;
    transform: translateY(50%);
    z-index: 10;
    font-size: 11px;
    font-weight: 400;
  }

  .product--quantity .product-q-inner input {
    height: calc(33px);
    border: none;
    padding: 15px 1em 0px;
    font-size: 16px;
    caret-color: #000;
    color: #000;
    max-width: 60px;
  }

  .product--select select {
    background-color: white;
    align-self: stretch;
    border: 1px solid rgb(198, 200, 202);
    border-radius: 0px;
    padding: 14px 32px 0px 16px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin: 0px;
    color: rgb(19, 19, 19);
    cursor: pointer;
    min-height: 50px;
    width: 100%;
  }

  .product--select {
    flex-grow: 1;
  }

  .product-slect-inner {
    position: relative;
  }

  .product--select label {
    color: rgb(19, 19, 19);
    position: absolute;
    pointer-events: none;
    left: 17px;
    bottom: calc(50% + 10px);
    transform: translateY(50%);
    font-size: 11px;
    font-weight: 400;
  }

  .product--increment .product-increment-inner {
    display: flex;
    min-height: 48px;
  }

  .product--increment .product-increment-inner .minus,
  .product--increment .product-increment-inner .plus {
    border: 1px solid rgb(198, 200, 202);
    border-radius: 2px 0 0 2px;
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .product--increment .product-increment-inner .minus:hover,
  .product--increment .product-increment-inner .plus:hover {
    background-color: rgb(239, 239, 239);
  }

  .product--increment .product-increment-inner .plus {
    border-radius: 0 2px 2px 0;
  }

  .product--increment .product-increment-inner input {
    border: none;
    border-top: 1px solid rgb(198, 200, 202);
    border-bottom: 1px solid rgb(198, 200, 202);
    min-height: 48px;
    text-align: center;
    padding: 0 10px;
    color: rgb(118, 189, 85);
    font-size: 24px;
    max-width: 160px;
  }

  .pre--range h4 {
    margin: 8px;
    font-size: 10px;
    text-align: center;
    color: #666;
    font-weight: 400;
  }

  .product--size-data {
    position: relative;
    top: 10px;
  }

  /* responsive */
  @media (max-width:767px) {
    .product--increment {
      position: absolute;
      top: 90px;
      width: calc(100% - 31px);
      right: 0;
    }

    .product--cart {
      min-height: 180px;
    }

    .increment--input {
      flex-grow: 1;
    }

    .product--increment .product-increment-inner input {
      max-width: 100%;
      width: 100%;
    }

    .remove--product {
      width: 100%;
      text-align: center;
      left: 0;
    }

    .product--select select {
      padding: 14px 10px 0px 16px;
    }
  }

  /*responsive end  */

  /* submit product */
  .submit--product--section {
    position: sticky;
    width: 100%;
    bottom: 0;
  }

  .product--item--header {
    background-color: rgb(239, 239, 239);
    padding: 0 15px;
  }

  .product--item--inner {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    max-width: 1000px;
    margin: 0 auto;
  }

  .product--item--inner h3 {
    color: rgb(19, 19, 19);
    font-size: 12px;
    line-height: 1.35;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin: 5px 0;
  }

  .product--item--inner h5 {
    color: rgb(19, 19, 19);
    font-size: 24px;
    line-height: 1.35;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin: 5px 0;

  }

  .view-product--wrapper img {
    width: 23px;
    position: relative;
    transition: all 0.4s;
  }

  .rotate--icon {
    transform: rotate(180deg);
  }

  .view-product--wrapper,
  .added-product--wrapper,
  .value-product--wrapper,
  .submit-product--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .product--item--inner>div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 0;
  }

  .submit-product--wrapper button {
    transition: all 0.1s ease 0s;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    line-height: 1.35;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    position: relative;
    padding: 0px 2.5em;
    border-radius: 0px;
    color: rgb(255, 255, 255);
    background-color: rgb(164, 29, 35);
    border: none;
    min-width: 220px;
    cursor: pointer;
  }

  .view-product--wrapper h3 {
    color: rgb(164, 29, 35);
  }

  .pre--aproved--text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
    background-color: #fff;
    width: max-content;
    padding: 0 1rem;
  }

  .pre--aproved {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .pre--aproved::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #c6c8ca;
    position: absolute;
    left: 0;
    top: 50%;
  }

  .pre--aproved--text img {
    width: 15px;
  }

  .pre--aproved--text h4 {
    color: #898b8e;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    font-family: Helvetica, Arial, sans-serif;
  }

  .pre-approved-data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 0 15px;
  }

  .pre-approved-data .pre-ap-data {
    text-align: center;
  }

  .pre-ap-data span {
    color: #898b8e;
    font-size: 14px;
    font-weight: 400;
    font-family: Helvetica, Arial, sans-serif;
  }

  .pre-ap-data img {
    width: 15px;
    position: relative;
    top: 3px;
    left: 5px;
  }

  .products--item--inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
  }

  .product--item img {
    width: 100%;
  }

  .products--items {
    margin-top: 3rem;
    padding: 0 15px;
  }

  .product--item {
    padding: 1rem;
    position: relative;
    text-align: center;
    cursor: pointer;
  }

  .product--item .badge {
    position: absolute;
    right: 10px;
    top: 0;
    background: #a41d23;
    width: 20px;
    height: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 13px;
  }

  .product--item h3 {
    font-size: 12px;
    line-height: 1.35;
    font-family: Helvetica, Arial, sans-serif;
    margin: 0px;
  }

  .product--item h5 {
    font-size: 12px;
    line-height: 1.35;
    font-family: Helvetica, Arial, sans-serif;
    margin: 5px 0;
  }

  .no--product--yet {
    text-align: center;
    padding: 2rem 0;
  }

  .no--product--yet span {
    color: #656565;
    font-size: 16px;
    line-height: 1.35;
    font-family: Helvetica, Arial, sans-serif;
  }

  .product--item--data {
    display: none;
  }

  .active--data {
    display: block;
  }

  @media (max-width:767px) {
    .product--item--inner {
      grid-template-columns: repeat(3, 1fr);
    }

    .submit--product {
      grid-column: span 3;
      padding: 0 0 2rem !important;
    }

    .submit-product--wrapper {
      width: 100%;
    }

    .products--item--inner {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }
 .card{
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    gap: 4%;
    JUSTIFY-CONTENT: CENTER;
    width: 100%;
    margin: 0 auto;
    max-width: 300px;
   ;
 }

 .collum{
   display: flex;
   justify-content: space-between;
   padding: 20px;
 }

 .card-title{
   min-height: 80px;
 }



.container{
  margin-top: 15px;
}

.search {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1)
}

.search input {
    height: 60px;
}
.search .fa-search {
    position: absolute;
    top: 20px;
    left: 16px
}

.search button {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 100px;
    background: blue
} 

.search input:focus {
    box-shadow: none;
    border: 2px solid blue
}
.c_img{
  height: 190px;
  object-fit: cover;
}

 .pagination{
   display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

   h3 {
    margin: 1rem 0;
    font-size: 18px ;
    justify-content: center;
    text-align: center;
  }

  h4{
    margin: 1rem 0;
    font-size: 15px ;
    justify-content: center;
    text-align: center;
  }
  h5{
    margin: 1rem 0;
    color: blue;
    font-size: 1rem;
    justify-content: center;
    text-align: center;
  }


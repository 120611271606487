.add_product {
    min-width: 100px;
    text-transform: uppercase;
    margin-top: 1em;
    max-width: 300px;

}
.add_product .btn{
transition: all 0.1s ease 0s;
    min-width: 160px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    line-height: 1.35;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    position: relative;
    padding: 0px 1.5em;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    background-color: rgb(19, 19, 19);
    border: none;
}
